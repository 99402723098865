<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Brands / Restaurants') }} > {{ $t('Brands') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Brands') }}</h2>
        <router-link :to="{ name: 'AddBrands' }">
          <el-button class="action-button" type="primary">{{ $t('Add Brand') }}</el-button>
        </router-link>
      </div>
      <div class="product-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Brand Logo') }}</th>
              <th scope="col">{{ $t('Brand Name') }}</th>
              <th scope="col">{{ $t('Relatd Restaurants Number') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="brand in brands">
              <td class="product-image-td">
                <div class="product-image-wrapper">
                  <img class="product-image img-fluid" v-if="brand.logo" :src="brand.logo" />
                  <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td>{{ brand.name }}</td>
              <td>1</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'EditBrand', params: {id: brand.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Brand from '@/lib/brand';

export default {
  name: 'Brands',
  mounted(){
    this.loadBrands();
  },
  methods: {
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    async loadBrands(){
      try{
        const loginInfo = Common.getLoginInfo();
        const brands = await Brand.loadAllBrands(this.apiUrl, loginInfo);
        this.brands = brands;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      brands: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}
.product-image-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  .product-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-image-td{
  width: 20%;
}
</style>
